import React, { Component } from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import jesonLebron from "../assets/img/sinan-cakir.jpg"
import team1 from "../assets/img/main/analiz-simay.jpg"
import analyse1 from "../assets/img/analyse1.png"
import analyse2 from "../assets/img/analyse2.png"
import analyse3 from "../assets/img/analyse3.png"
import checkModal from "../assets/img/check-modal.png";



class Analyse extends Component {
  constructor(props) {
    super(props);

    this.state = {
      display: false,
      value: ''
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    e.preventDefault();

    this.setState({ value: e.target.value });
  }
  showModal(e) {

    this.setState({ display: !this.state.display });
    document.querySelector('.check-modal').classList.add('active-modal');
  }

  closeModal() {
    this.setState({ display: !this.state.display })
    document.querySelector('.check-modal').classList.remove('active-modal');
  }
  handleSubmit(e) {
    e.preventDefault();
    const title = document.title.split('|',1).toString();  
    const response = fetch('https://forms.flatart.com.tr/index.php?r=app%2Ff&id=7', {
      method: 'POST',
      body: JSON.stringify({
        "text_327649": title,
        "text_204202": document.querySelector('#site').value,
        "text_775908": document.querySelector('#name').value,
        "text_532192": document.querySelector('#email').value,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8"
      }
    });
    response.then(res => {
      if (res.status == 200) {
        document.querySelector('.status').innerHTML = "Success!";
      }
      else {
        document.querySelector('.status').innerHTML = "Bir hata oluştu." 
      }
    });
  }
  render() {
    return (
      <Layout>
        <SEO title="Analysis" description="We analyze and interpret your brand data to take the actions your brand needs." />
        <div className="analyse">
          <div className="convert-hero container">
            <div className="col-md-4 noPadding">
              <div className="top">
                <h1>Analysis</h1>
                <p>We analyze and interpret your brand data to take the actions your brand needs.</p>
                <p onClick={(e) => this.showModal()} className="content-link">Get free brand analysis.</p>
              </div>
              <div className="bottom">
                <h2>robesNmore</h2>
                <p>“I am grateful to Flatart and its data-oriented approach for making our brand stand out in the international arena, despite the time difference our offices had.”</p>
                <div className="profile">
                  <div> <img alt="alt text" src={jesonLebron}></img></div>
                  <div><p>Sinan Cakir</p>
                    <p>Marketing Manager</p></div>
                </div>
              </div>
            </div>
            <div className="col-md-8 convert-image">
              <img alt="alt text" src={team1} />
              <div className="right-content">
                <div className="col-md-4">
                  <h3>Delivery in 2 Days</h3>
                  <p>We analyze the data within 2 days and present the results to you.</p>
                </div>
                <div className="col-md-4">
                  <h3>%100 Free</h3>
                  <p>We perform the analysis service without any charge from you.</p>
                </div>
                <div className="col-md-4">
                  <h3>No Authority Required</h3>
                  <p>We do not take your time to authorize our agents while analyzing your data in order to minimize your hustle.</p>
                </div>

              </div>
            </div>
          </div>


        </div>
        <div className="convert-traffic py60">
          <h2>Analysis services that will allow<br/> you to discover your brand.</h2>
          <p><span>SERVICES  <svg className="arrow-right" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 563 1061.4" space="preserve">
            <polygon points="123.1,0 0,100.7 356.3,536.2 0.3,958.9 121.9,1061.4 563,537.6 " />
          </svg></span> ANALYSIS</p>
        </div>

        <div>
          <div className="acquire-bottom__right" id="pazarlama-audit">
            <div className="acquire-bottom__left-content">
              <h2>Marketing & <br /> Brand Audit</h2>
              <p>We take a look into the current position of your brand in the market and detect opportunities in line with its weaknesses and strengths.</p>
              <p className="small-content">
                - Brand awareness control<br />
                - Brand assets audit<br />
                - Know-how audit
          </p>
              {/* <p style={{ marginBottom: 0 }}><Link to="#" className="content-link">Pazarlama & brand audit<br/> sürecine göz atın</Link></p> */}
            </div>
            <div className="acquire-bottom__right-img"><img alt="alt text" src={analyse1} /></div>
          </div>


          <div className="acquire-bottom__left" id="website-audit">
            <div className="acquire-bottom__left-img"><img alt="alt text" src={analyse2} /></div>
            <div className="acquire-bottom__left-content">
              <h2>Website UX &<br /> UI Audit</h2>
              <p>We discover areas where your website has difficulties for users and make it user-oriented to foster transformation.</p>
              <p className="small-content">
                - User analysis<br />
                - Interface control<br />
                - User experience control
          </p>
              <p style={{ marginBottom: 0 }}><Link to="/ui-ux-design/" className="content-link">Check out our Website UX & UI service.</Link></p>
            </div>
          </div>


          <div className="acquire-bottom__right" id="seo-audit">
            <div className="acquire-bottom__left-content">
              <h2>SEO & Content <br /> Audit</h2>
              <p>We analyze the SEO performance of the content on your website and identify the points that need improvement.</p>
              <p className="small-content">
                - Content auditing open to Index<br />
                - SEO performance analysis
          </p>
              <p style={{ marginBottom: 0 }}><Link to="/search-engine-optimization/" className="content-link">Check out our SEO & Content service.</Link></p>
            </div>
            <div className="acquire-bottom__right-img"><img alt="alt text" src={analyse3} /></div>
          </div>
        </div>

        <div className="container strategy py60">
          <h2>Creating A Strategic Plan</h2>
          <p>We create a customized strategy action plan for you in line with SEO, analytics, web positioning, social media, goals, and metrics.</p>
          <button className="red-button" onClick={(e) => this.showModal()}>Free Digital Performance Report</button>
        </div>

        <div className="check-modal">
          <span onClick={(e) => this.closeModal()}>X</span>
          <h2 className="form-head">Fill the form</h2>         
           <p className="form-desc">Enter your information in the fields to get information, and we will get back to you as soon as possible.</p>
          <div className="check-modal_container">
            <form onSubmit={this.handleSubmit} >
              <input type="text" value={this.state.value} onChange={this.handleChange} autoFocus={true} name="text_204202" id="site" required  placeholder="Enter your website." />
              <input type="text" id="name" required name="text_775908" placeholder="Enter your name" />
              <input type="email" id="email" required  name="text_532192" placeholder="Enter yor email" />
              <button>SEND</button>
              <div className="status"></div>
            </form>
            <img alt="alt text" src={checkModal} />
          </div>
        </div>
      </Layout>
    )
  }
}
export default Analyse
